.App {
  padding: 0 5%;

  overflow-y: scroll;
  max-height: 100vh;
  scroll-snap-type: y mandatory;
}

.centre {
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading {
  color: var(--too-light-yellow, #4a9d98);
  font-size: 6.25rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  line-height: 7.25rem;
}

@media (prefers-reduced-motion) {
  .hidden {
    transition: none;
  }
}

@media screen and (max-width: 1100px) {
  .App {
    max-height: unset;
    /* overflow-y: scroll; */
    scroll-snap-type: initial;
  }

  .heading {
    font-size: 5rem;
    line-height: unset;
  }

  .hidden {
    opacity: 0;
    filter: blur(5px);
    transition: all 1s;
  }

  .hidden-left {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-20%);
    transition: all 1s;
  }

  .show {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
  }

  /* .show-left {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
  } */
}

@media screen and (max-width: 550px) {
  .heading {
    font-size: 3.5rem;
  }
}

@media screen and (max-width: 400px) {
  .heading {
    font-size: 2.5rem;
  }
}
