.home {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;

  scroll-snap-align: start;
}

.home-text-heading {
  color: var(--dark-blue, #006963);
  font-size: 6.25rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  line-height: 7.25rem;
}

.home-text-sub-heading {
  color: var(--dark-blue, #006963);
  font-size: 1.75rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 15px;
}

.home-text-button {
  margin-top: 30px;

  width: 9.875rem;
  height: 3.8125rem;
  border-radius: 0.375rem;
  background: var(--yellow, #84f5e1);
  position: relative;
}

.home-text-button-text {
  width: 9.875rem;
  height: 3.8125rem;
  border-radius: 0.375rem;
  border: 1px solid var(--dark-brown, #198b9b);
  position: absolute;
  top: -10px;
  left: -10px;
  transition: top 0.5s, left 0.5s;
}

.home-text-button-text:hover {
  cursor: pointer;
  top: 0px;
  left: 0px;
}

.home-image {
  position: relative;
  display: flex;
  justify-content: center;
}

.home-image-profile {
}

.home-image-deco-1 {
  position: absolute;
  top: 0px;
  right: 30px;
  width: 10%;
}

.home-image-deco-2 {
  position: absolute;
  bottom: 60px;
  left: 0px;
  width: 20%;
}

@media screen and (max-width: 1100px) {
  .home {
    flex-direction: column-reverse;
    padding-top: 100px;
    justify-content: flex-end;
  }

  .home-image-profile {
    width: 80%;
  }
}

@media screen and (max-width: 700px) {
  .home {
    min-height: unset;
    scroll-snap-align: none;
  }
}
