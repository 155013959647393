.navbar-wrapper {
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  z-index: 1;
  left: 0;
  right: 0;
  background-color: #dffff4;
}

.navbar {
  display: flex;
  flex: row;
  z-index: 1;
  justify-content: space-between;
  margin-top: 25px;
  padding: 0 5%;
}

.navbar-name {
  color: var(--dark-blue, #006963);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
}

.navbar-menu {
  color: var(--dark-blue, #006963);
  font-size: 1.125rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;

  display: flex;
  flex-direction: row;
  justify-content: space-around;

  width: 30%;
}

.navbar-menu span:hover {
  cursor: pointer;
  text-decoration: underline;
}

.navbar-links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  width: 10%;
}

@media screen and (max-width: 1100px) {
  .navbar {
    justify-content: center;
    align-items: center;
  }
  .navbar-menu,
  .navbar-name {
    display: none;
  }

  .navbar-links {
    width: 50%;
  }
}
