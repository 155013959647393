.contact {
  min-height: 50vh;

  scroll-snap-align: start;
}

.contact-heading {
  color: var(--too-light-yellow, #4a9d98);
  font-size: 6.25rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  line-height: 7.25rem;
}

.contact-text {
  margin-left: 50px;
  color: var(--dark-blue, #006963);
  font-size: 1.5rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 2.75rem;
  margin-top: 30px;
}

@media screen and (max-width: 1100px) {
  .contact {
    min-height: unset;
    padding: 100px 0px;
    scroll-snap-align: none;
  }
}

@media screen and (max-width: 500px) {
  .contact-text {
    font-size: 1.25rem;
    margin: 0px;
  }
}

@media screen and (max-width: 350px) {
  .contact-text {
    font-size: 1rem;
  }
}
