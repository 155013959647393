.about {
  min-height: 250vh;

  scroll-snap-align: start;
  padding-top: 200px;
}

.about-text {
  color: var(--dark-blue, #006963);
  font-size: 1.5rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 2.75rem;

  max-width: 75ch;
  margin-top: 20px;
}

.about-text-decoration {
  text-decoration: underline;
  text-decoration-color: var(--dark-brown, #198b9b);
  /* background: var(--dark-brown, #198b9b);
  color: var(--light-yellow, #dffff4); */
}

.about-list {
  padding-left: 30%;
  color: var(--dark-blue, #006963);
}

.about-list li {
  list-style-image: url("../../assets/icons/ellipse.svg");
  margin-left: 5px;
}

.about-list-heading {
  font-size: 1.5rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 2.5rem;
  margin-top: 100px;
}

.about-list-sub-heading {
  font-size: 1.5rem;
  font-family: Poppins;
  font-style: italic;
  font-weight: 600;
  line-height: 2.5rem;
}

.about-list-text {
  font-size: 1.5rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 2.75rem;
}

@media screen and (max-width: 1100px) {
  .about-list {
    padding-left: 0px;
  }
}

@media screen and (max-width: 1100px) {
  .about {
    min-height: unset;
    padding-top: 100px;
    scroll-snap-align: none;
  }
}
