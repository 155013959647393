.skills {
  min-height: 120vh;

  scroll-snap-align: start;
  padding-top: 200px;
}

.skills-heading {
  color: var(--too-light-yellow, #4a9d98);
  font-size: 6.25rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  line-height: 7.25rem;
}

.skills-text {
  color: var(--dark-blue, #006963);
  font-size: 1.5rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 2.75rem;

  margin-top: 100px;
}

.skills-text div {
  margin-top: 50px;
}

@media screen and (max-width: 1100px) {
  .skills {
    min-height: unset;
    padding-top: 100px;
    scroll-snap-align: none;
  }
}
